// React imports

import React, { useEffect } from 'react'

// Remix imports

// Generated imports

// App imports
import Button from '~/components/buttons/Button'
import FacebookLoginForm from '~/components/forms/FacebookLoginForm'
import InputCheckbox from '~/components/inputs/InputCheckbox'
import InputText from '~/components/inputs/InputText'
import FormError from '~/components/typography/FormError'
import useFormErrorsAndData from '~/hooks/useFormErrorsAndData'
import useFormsDisabled from '~/hooks/useFormsDisabled'
import useLang from '~/hooks/useLang'
import type { LoginSignModalTrans } from '~/routes/$lang.modals/login-signup'
import urlReverse from '~/utils/urlReverse'

interface Props extends FormComponent {
  favoriteId?: string
  redirectTo?: string
  trans: LoginSignModalTrans
}

const SignupForm: React.FC<Props> = ({
  FormElement,
  actionData,
  className,
  favoriteId,
  redirectTo,
  trans,
}) => {
  const lang = useLang()
  const { formErrors, formData, nextURL } = useFormErrorsAndData(actionData)
  const disabled = useFormsDisabled()

  useEffect(() => {
    if (nextURL) {
      window.location.pathname = nextURL
    }
  }, [nextURL])

  let redirectToEncoded = redirectTo
    ? encodeURI(redirectTo)
    : encodeURI(`/${lang}/`)

  const privacyPolicyLink = `<a href="${urlReverse('privacy-policy', {
    lang,
  })}" target="_blank" style="text-decoration: underline;">${
    trans.PrivacyPolicy
  }</a>`
  const privacyPolicyLabel = trans.IHaveReadAndAcceptText.replace(
    '$(privacyPolicy)s',
    privacyPolicyLink
  )

  let actionUrl = `${urlReverse('signup', { lang })}?next=${redirectToEncoded}`
  if (favoriteId) {
    actionUrl += `&favorite_id=${favoriteId}`
  }

  return (
    <React.Fragment>
      <FormElement
        className={className}
        method="post"
        action={actionUrl}
      >
        {favoriteId && (
          <input
            type="hidden"
            name="favorite_id"
            value={favoriteId}
          />
        )}

        <input
          type="hidden"
          name="next"
          value={redirectToEncoded}
        />

        <InputText
          defaultValue={formData?.username}
          disabled={disabled}
          error={formErrors?.username}
          name="username"
          placeholder={trans.Email}
        />
        <InputText
          defaultValue={formData?.password}
          disabled={disabled}
          error={formErrors?.password}
          name="password"
          type="password"
          placeholder={trans.Password}
        />
        <InputCheckbox
          className="mb-3"
          defaultChecked={formData?.newsletter_signup == 'yes'}
          disabled={disabled}
          error={formErrors?.newsletter_signup}
          name="newsletter_signup"
          value="yes"
          label={trans.YesIWouldLikeToReceiveText}
        />
        <InputCheckbox
          className="mb-3"
          defaultChecked={formData?.privacy_policy == 'yes'}
          disabled={disabled}
          error={formErrors?.privacy_policy}
          name="privacy_policy"
          value="yes"
          dangerouslySetLabel={privacyPolicyLabel}
        />
        <input
          disabled={disabled}
          type="hidden"
          name="action"
          value="login"
        />

        <Button
          className="mt-3"
          disabled={disabled}
        >
          {trans.SignUp}
        </Button>

        <FormError
          className="mt-3 block"
          error={formErrors?.__all__}
        />
      </FormElement>
      <FacebookLoginForm
        lang={lang}
        trans={trans}
        nextUrl={redirectToEncoded}
      />
    </React.Fragment>
  )
}

export default SignupForm
