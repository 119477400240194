// React imports
import { useContext, useEffect, useState } from 'react'

// Remix imports
import { useFetcher, useLocation } from '@remix-run/react'
import type { HeadersFunction, LoaderFunction } from '@remix-run/server-runtime'

// Third party imports
import isEmpty from 'lodash/isEmpty'

// App imports
import queryString from 'query-string'
import Button from '~/components/buttons/Button'
import ButtonIAmATradeUser from '~/components/buttons/ButtonIamATradeUser'
import MainTinyCentered from '~/components/containers/MainTinyCentered'
import ForgotPasswordForm from '~/components/forms/ForgotPasswordForm'
import LoginForm from '~/components/forms/LoginForm'
import SignupForm from '~/components/forms/SignupForm'
import TradeUserCreateAccountFormPartial from '~/components/forms/TradeUserCreateAccountFormPartial'
import Modal from '~/components/modals/Modal'
import { ModalsContext } from '~/components/modals/Modals'
import FormError from '~/components/typography/FormError'
import H1 from '~/components/typography/H1'
import HR from '~/components/typography/HR'
import P2 from '~/components/typography/P2'
import TextLink from '~/components/typography/TextLink'
import { fetchGlobalGQL } from '~/fetch.server'
import useFormsDisabled from '~/hooks/useFormsDisabled'
import useLang from '~/hooks/useLang'
import type { PickTranslations } from '~/i18n.server'
import { getTranslations } from '~/i18n.server'
import type { Language } from '~/i18n.universal'
import { getLang, transArray } from '~/i18n.universal'
import CreateAccountSuccessModal from '~/routes/$lang.modals/create-account-success'
import { CACHE_CONTROL } from '~/utils/cacheControl'
import createMarkup from '~/utils/createMarkup'
import { loaderJson } from '~/utils/jsonResponse.server'
import urlReverse from '~/utils/urlReverse'

// Generated imports

const VIEW_CACHE = CACHE_CONTROL.NO_CACHE

// ============================================================================
// HTML =======================================================================
// ============================================================================
type showFormOptions = 'signup' | 'tradeSignup' | 'login' | 'forgotPassword'
interface Props {
  favoriteId?: string
  initialShowForm?: showFormOptions
}

export const LoginSignupModal: React.FC<Props> = ({
  favoriteId,
  initialShowForm,
}) => {
  const lang = useLang()
  const modalsCtx = useContext(ModalsContext)
  const [showForm, setShowForm] = useState<showFormOptions>(
    initialShowForm || 'signup'
  )
  const fetcherLoader = useFetcher<ModalLoginSignupLoaderData>()

  const fetcherLogin = useFetcher<any>()
  useEffect(() => {
    const isActionRedirect =
      fetcherLogin.state === 'loading' &&
      fetcherLogin.formMethod != null &&
      fetcherLogin.formMethod === 'POST' &&
      fetcherLogin.data === undefined

    // if we got no data, it must have been a redirect
    if (isActionRedirect && fetcherLogin.formData?.get('next') != `/${lang}/`) {
      const next = (fetcherLogin.formData?.get('next') as string) || `/${lang}/`
      window.location.href = next
    }
  }, [fetcherLogin, lang])

  const fetcherSignup = useFetcher<any>()

  const fetcherTradeSignup = useFetcher<any>()
  useEffect(() => {
    const isActionRedirect =
      fetcherTradeSignup.state === 'loading' &&
      fetcherTradeSignup.formMethod != null &&
      fetcherTradeSignup.formMethod === 'POST' &&
      fetcherTradeSignup.data === undefined
    // if we got no data, it must have been a redirect

    if (isActionRedirect && modalsCtx) {
      modalsCtx.replace('CreateAccountSuccess', <CreateAccountSuccessModal />)
    }
  }, [fetcherTradeSignup, modalsCtx, lang])

  const fetcherForgotPassword = useFetcher<any>()

  const location = useLocation()

  useEffect(() => {
    if (fetcherLoader.state === 'idle' && fetcherLoader.data == null) {
      fetcherLoader.load(`/${lang}/modals/login-signup/`)
    }
  }, [fetcherLoader, lang])

  const isDisabled = useFormsDisabled()

  if (isEmpty(fetcherLoader.data)) {
    return null
  }

  const trans = fetcherLoader.data!.trans

  const renderLogin = () => {
    return (
      <MainTinyCentered>
        <H1 className="mt-3">{trans.LogIn}</H1>
        <div className="mx-auto mt-3 w-[80%]">
          <LoginForm
            FormElement={fetcherLogin.Form}
            actionData={fetcherLogin.data}
            favoriteId={favoriteId}
            redirectTo={location.pathname}
            trans={trans}
          />
        </div>

        <HR />

        <P2 className="mt-3">
          <TextLink
            onClick={() => {
              setShowForm('forgotPassword')
            }}
          >
            {trans.ForgotPassword}
          </TextLink>
        </P2>
        <P2 className="mt-3">
          {trans.DontHaveAnAccount}{' '}
          <TextLink
            onClick={() => {
              setShowForm('signup')
            }}
          >
            {trans.SignUp}
          </TextLink>
        </P2>
      </MainTinyCentered>
    )
  }

  const renderSignup = () => {
    return (
      <MainTinyCentered>
        <div className="mx-auto w-[80%]">
          <H1 className="mt-3">{trans.SignUp}</H1>

          <ButtonIAmATradeUser
            trans={trans}
            lang={lang}
            isTradeSignup={false}
            className="mt-3"
            onChange={() => setShowForm('tradeSignup')}
          />

          <SignupForm
            FormElement={fetcherSignup.Form}
            actionData={fetcherSignup.data}
            favoriteId={favoriteId}
            redirectTo={location.pathname}
            trans={trans}
          />
        </div>

        <HR />

        <P2 className="mt-3">
          {trans.AlreadyHaveAnAccount}{' '}
          <TextLink
            onClick={() => {
              setShowForm('login')
            }}
          >
            {trans.LogIn}
          </TextLink>
        </P2>
      </MainTinyCentered>
    )
  }

  const renderTradeSignup = () => {
    const parsedQuery = queryString.parse(location.search)
    const next = parsedQuery.next as string
    const redirectToEncoded = encodeURI(next || location.pathname)

    return (
      <div>
        <div className="gap-8 md:flex">
          <MainTinyCentered className="md:min-w-[50%]">
            <H1 className="">{trans.ApplyNow}</H1>

            <ButtonIAmATradeUser
              trans={trans}
              lang={lang}
              isTradeSignup={true}
              className="mt-3"
              onChange={() => setShowForm('signup')}
            />

            <div className="mt-next-group-element flex text-left">
              <P2
                dangerouslySetInnerHTML={createMarkup(
                  trans.AreYouAnArtistText.replace(
                    '%(submissionsURL)s',
                    urlReverse('submissions', { lang })
                  )
                )}
              />
            </div>

            <fetcherTradeSignup.Form
              className="mt-next-group-element-2x"
              method="post"
              action={urlReverse('tradeSignup', { lang })}
            >
              <input
                type="hidden"
                name="next"
                value={redirectToEncoded}
              />

              <TradeUserCreateAccountFormPartial
                trans={trans}
                lang={lang}
                formErrors={fetcherTradeSignup.data?.formErrors}
                formData={fetcherTradeSignup.formData}
                isDisabled={isDisabled}
              />

              <Button
                disabled={isDisabled}
                className="mt-3"
              >
                {trans.ApplyNow}
              </Button>

              <div className="mt-3">
                <FormError
                  dangerouslySet={true}
                  error={fetcherTradeSignup.data?.formErrors?.__all__}
                />
              </div>
            </fetcherTradeSignup.Form>
          </MainTinyCentered>
          <div className="mt-next-element p-8 pt-0 text-left md:mt-0 md:border-l md:border-gray-border">
            <H1>{trans.ArtlingTradeApplication}</H1>
            <P2 className="mt-next-group-element leading-6">
              {trans.ArtlingTradeApplicationText}
            </P2>
          </div>
        </div>

        <HR />

        <P2 className="mt-3">
          {trans.AlreadyHaveAnAccount}{' '}
          <TextLink
            onClick={() => {
              setShowForm('login')
            }}
          >
            {trans.LogIn}
          </TextLink>
        </P2>
      </div>
    )
  }

  const renderForgotPassword = () => {
    return (
      <MainTinyCentered>
        <H1 className="mt-3">{trans.ForgotYourPassword}</H1>
        <P2 className="mt-3">{trans.EnterYourEmailAddressBelowText}</P2>
        <div className="mx-auto mt-6 w-[80%]">
          <ForgotPasswordForm
            FormElement={fetcherForgotPassword.Form}
            actionData={fetcherForgotPassword.data}
            trans={trans}
          />
        </div>

        <HR />

        <P2 className="mt-3">
          <TextLink
            onClick={() => {
              setShowForm('login')
            }}
          >
            {trans.BackToLogin}
          </TextLink>
        </P2>
      </MainTinyCentered>
    )
  }

  return (
    <Modal size="large">
      <div className="text-center">
        {showForm === 'login' && renderLogin()}
        {showForm === 'signup' && renderSignup()}
        {showForm === 'tradeSignup' && renderTradeSignup()}
        {showForm === 'forgotPassword' && renderForgotPassword()}
      </div>
    </Modal>
  )
}

// ============================================================================
// TRANS ======================================================================
// ============================================================================
const transArr = transArray([
  'AlreadyHaveAnAccount',
  'ApplyNow',
  'AreYouAnArtistText',
  'ArtlingTradeApplication',
  'ArtlingTradeApplicationText',
  'BackToLogin',
  'CompanyName',
  'CompanyWebsite',
  'CreateAnAccount',
  'DontHaveAnAccount',
  'Email',
  'EnterYourEmailAddressBelowText',
  'FacebookLoginWasCancelled',
  'FirstName',
  'ForgotPassword',
  'ForgotYourPassword',
  'IAmADesignProfessional',
  'IfWeHaveYourEmailAddressOnFileText',
  'IHaveReadAndAcceptText',
  'LastName',
  'LogIn',
  'LogInWithFacebook',
  'Mobile',
  'NewPassword',
  'Password',
  'PasswordReset',
  'PasswordResetInstructionsSent',
  'PasswordResetSuccessful',
  'PleaseGrantAccessToShareYourEmail',
  'PrivacyPolicy',
  'ReEnterNewPassword',
  'ResetMyPassword',
  'SignUp',
  'SignUpAndBeTheFirstText',
  'VerificationLinkExpired',
  'VerificationLinkExpiredText',
  'ThankYouForSigningUpForAnAccount',
  'TheArtlingLogo',
  'PleaseCheckYourEmailForALink',
  'YesIWouldLikeToReceiveForTradeText',
  'YesIWouldLikeToReceiveText',
  'YouHaveRequestedToResetThePasswordFor',
  'YouMayNowLogin',
  'YouMayNowLoginWithYourNewPassword',
  'YourCompanyEmail',
  'YourPasswordResetTokenHasExpired',
])

export type LoginSignModalTrans = PickTranslations<(typeof transArr)[number]>

export const getLoginSignupModalTrans = (lang: Language) => {
  return getTranslations(lang, transArr)
}

// ============================================================================
// LOADERS ====================================================================
// ============================================================================
export interface ModalLoginSignupLoaderData {
  lang: Language
  trans: LoginSignModalTrans
}

export const loader: LoaderFunction = async ({ request, params }) => {
  const lang = getLang(params)
  const trans = getLoginSignupModalTrans(lang)
  const { global, headers } = await fetchGlobalGQL(request, params)
  return loaderJson({ lang, trans, global }, VIEW_CACHE, { headers })
}

export default LoginSignupModal

// ============================================================================
// HEADERS ====================================================================
// ============================================================================
export const headers: HeadersFunction = ({ loaderHeaders }) => {
  return {
    'Cache-Control': VIEW_CACHE,
  }
}
