// React imports
import { useEffect, useState } from 'react'

// Remix imports
import { useFetcher } from '@remix-run/react'

// Third party imports
import FacebookLogin from '@greatsumini/react-facebook-login'

// Generated imports

// App imports
import Button from '~/components/buttons/Button'
import FormError from '~/components/typography/FormError'
import useIsMounted from '~/hooks/useIsMounted'
import type { Language } from '~/i18n.universal'
import type { LoginSignModalTrans } from '~/routes/$lang.modals/login-signup'
import FacebookLogoSvgUrl from '~/static/svg/FacebookLogo.svg'
import urlReverse from '~/utils/urlReverse'

// ============================================================================
// HTML =======================================================================
// ============================================================================
interface Props {
  lang: Language
  trans: LoginSignModalTrans
  nextUrl?: string
}

const FacebookLoginForm: React.FC<Props> = ({ lang, trans, nextUrl }) => {
  const { isMounted } = useIsMounted()
  const fetcher = useFetcher()
  const [needsRerequest, setNeedsRerequest] = useState(false)
  const [formError, setFormError] = useState<undefined | string>(undefined)

  useEffect(() => {
    if (fetcher.data?.data?.facebookAuth?.status === 200) {
      window.location.href = nextUrl ? decodeURI(nextUrl) : `/${lang}/`
    }
  }, [fetcher.data, lang, nextUrl])

  const handleResponseFacebook = (res: any) => {
    if (!res.accessToken) {
      setNeedsRerequest(true)
      setFormError(trans.FacebookLoginWasCancelled)
      return
    }

    if (res.grantedScopes.indexOf('email') < 0) {
      setNeedsRerequest(true)
      setFormError(trans.PleaseGrantAccessToShareYourEmail)
      return
    }

    fetcher.submit(
      { accessToken: res.accessToken },
      { method: 'post', action: `${urlReverse('loginFacebook', { lang })}` }
    )
  }

  if (typeof window === 'undefined') {
    return null
  }

  if (!isMounted) {
    return null
  }

  return (
    <div className="mt-3 flex flex-col justify-center">
      <div className="flex justify-center">
        <FacebookLogin
          appId={window.ENV.FACEBOOK_APP_ID}
          initParams={{
            version: 'v16.0',
            localStorage: false,
          }}
          scope="email"
          loginOptions={{
            auth_type: needsRerequest ? 'rerequest' : undefined,
            return_scopes: true,
          }}
          fields="name,email"
          onSuccess={(res: any) => handleResponseFacebook(res)}
          onFail={(res: any) => handleResponseFacebook(res)}
          render={({ onClick }) => (
            <Button
              className="flex flex-row items-center justify-center bg-[#1278F3] pl-[10px] pr-[20px] text-white hover:bg-[#1278F3] hover:opacity-80"
              onClick={onClick}
              custom={true}
            >
              <img
                src={FacebookLogoSvgUrl}
                alt="Facebook Logo"
                className="mr-2 mt-[-5px] w-[30px]"
              />
              {trans.LogInWithFacebook}
            </Button>
          )}
        />
      </div>
      <div>
        <FormError error={formError} />
      </div>
    </div>
  )
}

export default FacebookLoginForm
