// NOTE: This is a Form Partial, you will have to wrap this with a FormElement
//       for proper form handling.

import React from 'react'
import InputCheckbox from '~/components/inputs/InputCheckbox'

import InputText from '~/components/inputs/InputText'
import type { Language } from '~/i18n.universal'
import type { LoginSignModalTrans } from '~/routes/$lang.modals/login-signup'
import urlReverse from '~/utils/urlReverse'

interface Props {
  trans: LoginSignModalTrans
  formErrors?: any
  formData?: any
  isDisabled?: boolean
  lang: Language
  className?: string
}

const TradeUserCreateAccountFormPartial: React.FC<Props> = ({
  className,
  trans,
  formErrors,
  formData,
  isDisabled,
  lang,
}) => {
  const privacyPolicyLink = `<a href="${urlReverse('privacy-policy', {
    lang,
  })}" target="_blank" rel="noopener noreferrer" style="text-decoration: underline;">${
    trans.PrivacyPolicy
  }</a>`
  const privacyPolicyLabel = trans.IHaveReadAndAcceptText.replace(
    '$(privacyPolicy)s',
    privacyPolicyLink
  )

  return (
    <div className={className}>
      <div className="grid grid-cols-2 gap-4">
        <InputText
          error={formErrors?.first_name}
          name="first_name"
          label={trans.FirstName}
          disabled={isDisabled}
        />
        <InputText
          error={formErrors?.last_name}
          name="last_name"
          label={trans.LastName}
          disabled={isDisabled}
        />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <InputText
          error={formErrors?.email}
          name="email"
          label={trans.YourCompanyEmail}
          disabled={isDisabled}
        />
        <InputText
          error={formErrors?.mobile}
          name="mobile"
          label={trans.Mobile}
          disabled={isDisabled}
        />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <InputText
          error={formErrors?.company_name}
          name="company_name"
          label={trans.CompanyName}
          disabled={isDisabled}
        />
        <InputText
          error={formErrors?.company_website}
          name="company_website"
          label={trans.CompanyWebsite}
          disabled={isDisabled}
        />
      </div>
      <InputCheckbox
        className="mb-3"
        defaultChecked={formData?.newsletter_signup == 'yes'}
        disabled={isDisabled}
        error={formErrors?.newsletter_signup}
        name="newsletter_signup"
        value="yes"
        label={trans.YesIWouldLikeToReceiveForTradeText}
      />
      <InputCheckbox
        className="mb-3"
        defaultChecked={formData?.privacy_policy == 'yes'}
        disabled={isDisabled}
        error={formErrors?.privacy_policy}
        name="privacy_policy"
        value="yes"
        dangerouslySetLabel={privacyPolicyLabel}
      />
      <input
        disabled={isDisabled}
        type="hidden"
        name="action"
        value="tradeSignup"
      />
    </div>
  )
}

export default TradeUserCreateAccountFormPartial
