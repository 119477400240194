// React imports

// Generated imports

// App imports
import Button from '~/components/buttons/Button'
import useFormErrorsAndData from '~/hooks/useFormErrorsAndData'
import useFormsDisabled from '~/hooks/useFormsDisabled'
import useLang from '~/hooks/useLang'
import type { LoginSignModalTrans } from '~/routes/$lang.modals/login-signup'
import urlReverse from '~/utils/urlReverse'
import InputText from '../inputs/InputText'
import FormError from '../typography/FormError'

interface Props extends FormComponent {
  trans: LoginSignModalTrans
}

const ForgotPasswordForm: React.FC<Props> = ({
  FormElement,
  actionData,
  className,
  trans,
}) => {
  const lang = useLang()
  const disabled = useFormsDisabled()
  const { formErrors, formData } = useFormErrorsAndData(actionData)

  return (
    <FormElement
      className={className}
      method="post"
      action={`${urlReverse('passwordReset', { lang })}`}
    >
      <InputText
        defaultValue={formData?.email}
        disabled={disabled}
        error={formErrors?.email}
        name="email"
        placeholder={trans.Email}
      />

      <Button
        type="submit"
        disabled={disabled}
        className="mt-6"
      >
        {trans.ResetMyPassword}
      </Button>

      <FormError
        error={formErrors?.__all__}
        className="mt-3 block"
      />
    </FormElement>
  )
}

export default ForgotPasswordForm
