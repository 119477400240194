import { Link } from '@remix-run/react'
import InputCheckbox from '~/components/inputs/InputCheckbox'
import type { Language } from '~/i18n.universal'
import type { LoginSignModalTrans } from '~/routes/$lang.modals/login-signup'
import urlReverse from '~/utils/urlReverse'

interface Props {
  lang: Language
  trans: LoginSignModalTrans
  isTradeSignup: boolean
  className?: string
  onChange?: () => void
}

const ButtonIAmATradeUser: React.FC<Props> = ({
  lang,
  trans,
  isTradeSignup,
  className,
  onChange,
}) => {
  const url = isTradeSignup
    ? urlReverse('signup', { lang })
    : urlReverse('tradeSignup', { lang })

  const renderCheckbox = () => {
    return (
      <InputCheckbox
        label={trans.IAmADesignProfessional}
        defaultChecked={isTradeSignup}
        name="is_trade_signup"
        onChange={() => {
          if (onChange) {
            onChange()
          }
        }}
      />
    )
  }

  const renderWithLink = () => {
    return (
      <Link
        to={url}
        prefetch="intent"
      >
        {renderCheckbox()}
      </Link>
    )
  }

  return (
    <div className={className}>
      {onChange ? renderCheckbox() : renderWithLink()}
    </div>
  )
}

export default ButtonIAmATradeUser
