// React imports

// Remix imports
import React, { useEffect } from 'react'

// Third party imports

// App imports
import Button from '~/components/buttons/Button'
import FacebookLoginForm from '~/components/forms/FacebookLoginForm'
import InputText from '~/components/inputs/InputText'
import FormError from '~/components/typography/FormError'
import useFormErrorsAndData from '~/hooks/useFormErrorsAndData'
import useFormsDisabled from '~/hooks/useFormsDisabled'
import useLang from '~/hooks/useLang'
import type { LoginSignModalTrans } from '~/routes/$lang.modals/login-signup'
import urlReverse from '~/utils/urlReverse'

interface Props extends FormComponent {
  favoriteId?: string
  redirectTo?: string
  trans: LoginSignModalTrans
}

const LoginForm: React.FC<Props> = ({
  FormElement,
  actionData,
  className,
  favoriteId,
  redirectTo,
  trans,
}) => {
  const lang = useLang()
  const { formErrors, formData, nextURL } = useFormErrorsAndData(actionData)
  const disabled = useFormsDisabled()

  useEffect(() => {
    if (nextURL) {
      window.location.pathname = nextURL
    }
  }, [nextURL])

  let redirectToEncoded = redirectTo
    ? encodeURI(redirectTo)
    : encodeURI(`/${lang}/`)

  let actionUrl = `${urlReverse('login', { lang })}?next=${redirectToEncoded}`
  if (favoriteId) {
    actionUrl += `&favorite_id=${favoriteId}`
  }

  return (
    <React.Fragment>
      <FormElement
        method="post"
        action={actionUrl}
        className={'text-center' + (className ? ` ${className}` : '')}
      >
        {favoriteId && (
          <input
            type="hidden"
            name="favorite_id"
            value={favoriteId}
          />
        )}

        <input
          type="hidden"
          name="next"
          value={redirectToEncoded}
        />

        <InputText
          defaultValue={formData?.username}
          disabled={disabled}
          error={formErrors?.username}
          name="username"
          placeholder={trans.Email}
        />
        <InputText
          defaultValue={formData?.password}
          disabled={disabled}
          error={formErrors?.password}
          name="password"
          placeholder={trans.Password}
          type="password"
        />

        <div className="mx-auto max-w-[215px]">
          <Button
            type="submit"
            disabled={disabled}
            className="h-[41px] w-full"
          >
            {trans.LogIn}
          </Button>
        </div>

        <FormError
          className="mt-3 block"
          error={formErrors?.__all__}
        />
      </FormElement>

      <FacebookLoginForm
        lang={lang}
        trans={trans}
        nextUrl={redirectToEncoded}
      />
    </React.Fragment>
  )
}

export default LoginForm
